<template>
  <div class="card-content collapse show">
    <div class="card-body">
      <!-- <div class="alert alert-warning">
        <div class="alert-body">
          This form is strictly confidential - like everything else.
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-sm-4">
          <p>Peer name<br></p>
        </div>
        <div class="col-sm-8">
          <p>Institution<br></p>
        </div>
      </div> -->
      <hr>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>Research impact</strong></label>
        <p><small>Recognition of excellence in terms of reach, significance and impact of the research within the relevant field/discipline. This is demonstrated through a sustained and distinguished record of publication of world leading or internationally excellent research outputs with demonstrable impact on leading researchers and the research agenda within the discipline.</small></p>
        <quill-editor v-model="promoEvaluation.research_impact" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>Research leadership</strong></label>
        <p><small>International recognition as an authority and leader in the field/discipline or professional community with demonstrable impact on the strategic direction of future research through the opinion of worldwide renowned referees on creativity, international influence, ability to develop a team, independence, etc. A sustained record of developing and leading major programmes of individual or collaborative research for national or international grants or other awards, as appropriate to the discipline; number and significance of funded projects, grants, and awards obtained; an extensive and sustained record of successful PhD supervision and completion.</small></p>
        <quill-editor v-model="promoEvaluation.research_leadership" />
      </div>
      <div class="mb-2">
        <label
          for=""
          class="form-label"
        ><strong>General Comments and Recommendations</strong></label>
        <div class="row">
          <div class="col-sm-6 form-group required">
            <label
              for=""
              class="form-label"
            >Positive</label>
            <quill-editor v-model="promoEvaluation.positive_overall_comments" />
          </div>
          <div class="col-sm-6 form-group required">
            <label
              for=""
              class="form-label"
            >To be improved</label>
            <quill-editor v-model="promoEvaluation.negative_overall_comments" />
          </div>
        </div>
      </div>

      <hr>

      <h4 class="mb-2">
        Mark from 1 to 10 the track record and scientific leadership of the candidate following the criteria below:
      </h4>

      <div class="row">
        <div class="col-sm-3 form-group required">
          <div class="mb-1">
            <p><strong>Mark</strong></p>
            <v-select
              v-model="promoEvaluation.peer_grade"
              label="name"
              :options="[
                {name: 1, value: 1},
                {name: 2, value: 2},
                {name: 3, value: 3},
                {name: 4, value: 4},
                {name: 5, value: 5},
                {name: 6, value: 6},
                {name: 7, value: 7},
                {name: 8, value: 8},
                {name: 9, value: 9},
                {name: 10, value: 10}
              ]"
              :get-option-key="option => option.name"
              :reduce="option => option.value"
            />
          </div>
        </div>
        <div class="col-sm-9">
          <div class="p-1 form-group card border-secondary">
            <p><strong>Marking criteria</strong></p>
            <b-tabs>
              <b-tab active>
                <template #title>
                  1
                </template>
                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Essentially </span><strong>non-competitive</strong> research outputs for<strong />international standards.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>No experience</strong> in leading<span> research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>Lack of evidence</strong> of engagement with<span> internationally recogni</span>z<span>ed groups</span>.
                  </p>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  2 to 3
                </template>
                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>General</strong><span><strong>ly </strong></span><strong /><span>research </span>outputs with <span>quality that is </span><span><strong>recogni</strong></span><strong>z</strong><span><strong>ed internationally</strong></span><span> in terms of originality, significance and rigor.</span> <strong>Minor d</strong><span><strong>oubts</strong></span><span> about </span><span><strong>personal contribution</strong></span><span> to outputs and ideas in joint work</span>.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>Some experience</strong> in leading<span> research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>Minor </strong><strong /><strong>evidence</strong> of engagement with<span> internationally recogni</span>z<span>ed groups</span>.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <strong>Minor</strong> strategic planning of research
                  </p>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  3 to 5
                </template>
                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                    <strong>Mostly </strong>outputs of <span>research quality that is </span><strong>recognized</strong> <span><strong>internationally </strong></span><span>in terms of originality, significance and rigor</span>; <span>and </span><span><strong>also possible</strong></span><span> research </span>outputs of <span>quality that is </span><span><strong>internationally excellent</strong></span>. <strong>No </strong><span><strong>doubts</strong></span><span> about </span><span><strong>personal contribution</strong></span><span> to outputs and ideas in joint work.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                    <strong>Experience</strong> in leading<span> research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                    <span><strong>Some engagement</strong></span><span> with the international community in the field.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; line-height: 100%">
                    <span><strong>Some thought</strong></span><span> given to planning</span> of research.
                  </p>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  5 to 7
                </template>
                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span><strong>Clear outputs</strong></span><span> with research quality that is </span><span><strong>internationally excellent</strong></span><span> in terms of originality, significance and rigor, but which falls short of the highest standards of excellence.</span> <span><strong>Some evidence</strong></span><span> of </span><span><strong>personal contribution</strong></span><span> to ideas in joint outputs.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Demonstrated ability to propose and conduct </span><span><strong>national</strong></span><span> research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span><strong>Some national awards</strong></span><span> or prizes. Good engagement with the international community in the field.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Adequate planning of research</span> producing results of <span><strong>international</strong></span> <span><strong>excellence</strong></span>.
                  </p>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  7 to 9
                </template>

                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Publication record </span><span><strong>contains</strong></span><span> outputs with research quality that is </span><span><strong>internationally excellent</strong></span><span> in terms of originality, significance and rigor</span>,<span> but which falls short of the highest standards of excellence</span> <span>and </span><span><strong>also possible</strong></span><span> research quality that is </span><span><strong>world-leading</strong></span>. <strong>E</strong><span><strong>vidence</strong></span><span> of </span><span><strong>personal contribution</strong></span><span> to ideas</span>.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Demonstrated ability to propose and conduct </span><span><strong>national</strong></span> and <strong>possible international</strong> <span>research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Some </span><span><strong>national and international awards</strong></span><span> and prizes and other measures of </span><span><strong>international esteem</strong></span>.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Research project </span><span><strong>timely, innovative</strong></span><span> research, and </span><span><strong>well planned</strong></span><strong>, </strong><span>which will </span>certainly <span>be of </span><span><strong>international</strong></span> <span><strong>excellence</strong></span> and <strong>likely to be world-leading</strong><strong>.</strong>
                  </p>
                </div>
              </b-tab>
              <b-tab>
                <template #title>
                  10
                </template>
                <div>
                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span><strong>Clear</strong></span><span> record of outputs and </span><span><strong>clear evidence of personal contribution</strong></span><span> to research quality that is </span><span><strong>world-leading</strong></span><span> in terms of originality, significance and rigor.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Demonstrated ability to propose and conduct </span><span><strong>international</strong></span><span> research projects.</span>
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Exposure to </span><span><strong>world leading</strong></span><span> research teams. Very impressive record of </span><span><strong>international awards and esteem</strong></span>.
                  </p>

                  <p style="margin-bottom: 0.21cm; border: none; padding: 0cm; font-variant: normal; letter-spacing: normal; font-style: normal; font-weight: normal; line-height: 100%; text-decoration: none">
                    <span>Research project </span><span><strong>highly innovative and timely</strong></span><span>, and </span><span><strong>planned in careful detail</strong></span>, that will <strong>definitively</strong> produce <strong>world-leading</strong> research.
                  </p>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import { BTab, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BTab,
    BTabs,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      promoEvaluation: 'convo/promoEvaluation',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>

<template>
  <div class="card-content collapse show">
    <div class="card-body">
      <!-- <div class="alert alert-warning">
        <div class="alert-body">
          This form is strictly confidential - like everything else.
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-sm-4">
          <p>Host name<br><strong>{{ loggedUser.full_name }}</strong></p>
        </div>
        <div class="col-sm-4">
          <p>Host position<br><strong>Director</strong></p>
        </div>
        <div class="col-sm-4">
          <p>Institution<br><strong>{{ loggedUser.hosts && loggedUser.hosts[0] ? loggedUser.hosts[0].name : '-' }}</strong></p>
        </div>
      </div> -->
      <hr>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.1. Adaptation process in the workplace</strong></label>
        <quill-editor v-model="promoEvaluation.adaptation_process" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.2. Relationship with colleagues</strong></label>
        <quill-editor v-model="promoEvaluation.colleagues_relationship" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.3. Relationship with students</strong></label>
        <quill-editor v-model="promoEvaluation.students_relationship" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.4. Teaching and participation in master or doctoral programmes</strong></label>
        <quill-editor v-model="promoEvaluation.programmes_participation" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.5. Schedule and availability</strong></label>
        <quill-editor v-model="promoEvaluation.schedule" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.6. Organizational work</strong></label>
        <quill-editor v-model="promoEvaluation.organizational_work" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.7. Team leadership</strong></label>
        <quill-editor v-model="promoEvaluation.team_leadership" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.8. Participation in the unit's activities</strong></label>
        <p><small>The unit is the department, research centre or laboratory where the ICREA researcher carries out her/his work.</small></p>
        <quill-editor v-model="promoEvaluation.activity_participation" />
      </div>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>1.9. Comments</strong></label>
        <p><small>You may provide any additional comments that may help the ICREA Promotion Committee properly assess the degree of integration of the researcher in her/his host institution. Comments on how this integration might be improved are also welcome.</small></p>
        <quill-editor v-model="promoEvaluation.general_comments" />
      </div>
      <div class="mb-2">
        <label
          for=""
          class="form-label"
        ><strong>1.10. The researcher's degree of integration is</strong></label>
        <div class="row">
          <div class="col-sm-3">
            <div class="mb-1 form-group required">
              <select
                v-model="promoEvaluation.degree"
                class="form-select"
              >
                <option value="">
                  None
                </option>
                <option value="5">
                  Outstanding
                </option>
                <option value="4">
                  Very Good
                </option>
                <option value="3">
                  Fair
                </option>
                <option value="2">
                  Inadequate
                </option>
                <option value="1">
                  Unacceptable
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'

export default {
  components: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      promoEvaluation: 'convo/promoEvaluation',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">2. Host Institution: additional information</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="mb-2 form-group required">
          <label
            for=""
            class="form-label"
          ><strong>2.1. Facilities</strong></label>
          <p><small>Please comment on working space, access to labs, equipment, etc. made available to the ICREA researcher.</small></p>
          <quill-editor v-model="promoEvaluation.facilities" />
        </div>
        <div class="mb-2 form-group required">
          <label
            for=""
            class="form-label"
          ><strong>2.2. Working conditions over the last 3 or 5 years</strong></label>
          <p><small>Please comment on any change related to initial working conditions.</small></p>
          <quill-editor v-model="promoEvaluation.working_conditions" />
        </div>
        <div class="mb-2 form-group required">
          <label
            for=""
            class="form-label"
          ><strong>2.3. Opportunities offered by the host to the ICREA researcher</strong></label>
          <p><small>Please comment briefly on the opportunities offered by the host institution for teaching and training, for taking a leading role in the unit’s activities (head of group, lab, department, etc.), for being appointed as academic/research staff (university/school professor, associate lecturer, member of the university or institute board, etc.</small></p>
          <quill-editor v-model="promoEvaluation.offered_oportunities" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      promoEvaluation: 'convo/promoEvaluation',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>

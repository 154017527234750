<template>
  <div class="card-content collapse show">
    <div class="card-body">
      <!-- <div class="alert alert-warning">
        <div class="alert-body">
          This form is strictly confidential - like everything else.
        </div>
      </div> -->
      <!-- <div class="row">
        <div class="col-sm-4">
          <p>PC Member name<br></p>
        </div>
        <div class="col-sm-8">
          <p>Institution<br><strong>{{ loggedUser.hosts && loggedUser.hosts[0] ? loggedUser.hosts[0].name : '-' }}</strong></p>
        </div>
      </div> -->
      <hr>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>Research impact</strong></label>
        <p><small>Recognition of excellence in terms of reach, significance and impact of the research within the relevant field/discipline. This is demonstrated through a sustained and distinguished record of publication of world leading or internationally excellent research outputs with demonstrable impact on leading researchers and the research agenda within the discipline.</small></p>
        <quill-editor v-model="promoEvaluation.research_impact" />
        <div class="mb-1 mt-1 row">
          <div class="col-sm-3 col-lg-2">
            <label
              class="col-form-label"
              for=""
            ><strong>Qualification</strong></label>
          </div>
          <div class="col-sm-3 form-group required">
            <select
              v-model="promoEvaluation.research_impact_qualification"
              class="form-select"
            >
              <option value="">
                None
              </option>
              <option value="5">
                Outstanding
              </option>
              <option value="4">
                Very Good
              </option>
              <option value="3">
                Fair
              </option>
              <option value="2">
                Inadequate
              </option>
              <option value="1">
                Unacceptable
              </option>
            </select>
          </div>
        </div>
      </div>
      <hr>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>Research leadership</strong></label>
        <p><small>International recognition as an authority and leader in the field/discipline or professional community with demonstrable impact on the strategic direction of future research through the opinion of worldwide renowned referees on creativity, international influence, ability to develop a team, independence, etc. A sustained record of developing and leading major programmes of individual or collaborative research for national or international grants or other awards, as appropriate to the discipline; number and significance of funded projects, grants, and awards obtained; an extensive and sustained record of successful PhD supervision and completion.</small></p>
        <quill-editor v-model="promoEvaluation.research_leadership" />
        <div class="mb-1 mt-1 row">
          <div class="col-sm-3 col-lg-2">
            <label
              class="col-form-label"
              for=""
            ><strong>Qualification</strong></label>
          </div>
          <div class="col-sm-3 form-group required">
            <select
              v-model="promoEvaluation.research_leadership_qualification"
              class="form-select"
            >
              <option value="">
                None
              </option>
              <option value="5">
                Outstanding
              </option>
              <option value="4">
                Very Good
              </option>
              <option value="3">
                Fair
              </option>
              <option value="2">
                Inadequate
              </option>
              <option value="1">
                Unacceptable
              </option>
            </select>
          </div>
        </div>
      </div>
      <hr>
      <div class="mb-2 form-group required">
        <label
          for=""
          class="form-label"
        ><strong>Future Research Plan</strong></label>
        <p><small>A well structured, clearly laid out plan where feasibility, methodology, originality and potential impact can be assessed.</small></p>
        <quill-editor v-model="promoEvaluation.future_research_plan" />
        <div class="mb-1 mt-1 row">
          <div class="col-sm-3 col-lg-2">
            <label
              class="col-form-label"
              for=""
            ><strong>Qualification</strong></label>
          </div>
          <div class="col-sm-3 form-group required">
            <select
              v-model="promoEvaluation.future_research_plan_qualification"
              class="form-select"
            >
              <option value="">
                None
              </option>
              <option value="5">
                Outstanding
              </option>
              <option value="4">
                Very Good
              </option>
              <option value="3">
                Fair
              </option>
              <option value="2">
                Inadequate
              </option>
              <option value="1">
                Unacceptable
              </option>
            </select>
          </div>
        </div>
        <hr>
      </div>
      <div class="mb-2">
        <label
          for=""
          class="form-label"
        ><strong>General Comments and Recommendations</strong></label>
        <div class="row">
          <div class="col-sm-6 form-group required">
            <label
              for=""
              class="form-label"
            >Positive</label>
            <quill-editor v-model="promoEvaluation.positive_overall_comments" />
          </div>
          <div class="col-sm-6 form-group required">
            <label
              for=""
              class="form-label"
            >To be improved</label>
            <quill-editor v-model="promoEvaluation.negative_overall_comments" />
          </div>
        </div>
        <div class="mb-1 mt-1 row">
          <div class="col-sm-3 col-lg-2">
            <label
              class="col-form-label"
              for=""
            ><strong>Qualification</strong></label>
          </div>
          <div class="col-sm-3 form-group required">
            <select
              v-model="promoEvaluation.overall_comments_qualification"
              class="form-select"
            >
              <option value="">
                None
              </option>
              <option value="5">
                Outstanding
              </option>
              <option value="4">
                Very Good
              </option>
              <option value="3">
                Fair
              </option>
              <option value="2">
                Inadequate
              </option>
              <option value="1">
                Unacceptable
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'

export default {
  components: {
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      promoEvaluation: 'convo/promoEvaluation',
      loggedUser: 'auth/admin',
    }),
  },
}
</script>
